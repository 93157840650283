export default function ProgressiveDisclosure() {
  function getDisclosureType(disclosure) {
    switch (disclosure.tagName.toLowerCase()) {
      case 'input':
        return disclosure.type.toLowerCase();
      default:
        return disclosure.tagName.toLowerCase();
    }
  }

  function disclose(event, options = {}) {
    const { disclosure = event.target } = options;

    // Only run on elements that have the [data-disclosure] attribute
    // If the disclosure doesn't have the attribute, return
    if (!disclosure.hasAttribute('data-disclosure')) return;

    // Get the content associated with the button
    const content = document.querySelector(
      `#${disclosure.getAttribute('aria-controls')}`
    );

    // No point continuing without content
    if (!content) {
      console.log(
        `Content couldn't be found for '#${disclosure.getAttribute(
          'aria-controls'
        )}`
      );
      return;
    }

    let showContent = false;
    let disclosureValue = null;
    switch (getDisclosureType(disclosure)) {
      case 'radio':
        // if we have a radio button, we also need to know what value will trigger a show
        if (disclosure.hasAttribute('data-disclosure-value')) {
          disclosureValue = disclosure.getAttribute('data-disclosure-value');
        }
        if (!disclosureValue) {
          console.log('No disclosure value specified for radio disclosure');
        }
        // show content if disclosure value == the value of the input disclosure
        showContent =
          disclosure.checked && disclosure.value === disclosureValue;
        break;
      default:
        // If the content is visible, hide it
        // Otherwise, show it
        showContent = disclosure.getAttribute('aria-expanded') === 'true';
        break;
    }

    if (showContent) {
      disclosure.setAttribute('aria-expanded', true);
      content.classList.remove('hidden');
    } else {
      // sometimes we only want to process showing the content, not hiding it.
      disclosure.setAttribute('aria-expanded', false);
      content.classList.add('hidden');
    }
  }

  window.addEventListener('DOMContentLoaded', () => {
    // Get the disclosure trigger elements
    const disclosures = Array.prototype.slice.call(
      document.querySelectorAll('[data-disclosure]')
    );

    const groupedDisclosures = {};

    // unflatten the list!
    disclosures.forEach((disclosure) => {
      const groupedDisclosure = groupedDisclosures[disclosure.name] || {};

      groupedDisclosure.type =
        groupedDisclosure.type || getDisclosureType(disclosure);
      switch (groupedDisclosure.type) {
        case 'radio':
          groupedDisclosure.elements = groupedDisclosure.elements || [];
          groupedDisclosure.elements.push(disclosure);
          break;
        default:
          groupedDisclosure.element = disclosure;
      }

      // add to main object
      groupedDisclosures[disclosure.name] = groupedDisclosure;
    });

    // we'd hopefully end up with this object in our groupedDisclosures
    // {
    //   "dln": {
    //     "type": "text",
    //     "element": Element,
    //   },
    //   "informationCorrect": {
    //     "type": "radio",
    //     "elements": [
    //       Element,
    //       Element
    //     ]
    //   }
    // }

    // Loop through them with Array.forEach()
    Object.keys(groupedDisclosures).forEach((key) => {
      const disclosure = groupedDisclosures[key];

      // add the appropriate listener depending on the trigger element, providing it's a form element
      switch (disclosure.type) {
        case 'radio':
          if (disclosure.elements) {
            let checkedElement = null;

            disclosure.elements.forEach((element) => {
              element.addEventListener('change', disclose);

              checkedElement = element.checked ? element : null;
            });

            if (checkedElement) {
              disclose(null, { disclosure: checkedElement });
            } else {
              disclose(null, { disclosure: disclosure.elements[0] });
            }
          }

          break;
        default:
          disclosure.element.addEventListener('click', disclose);
          disclose(null, { disclosure });
          break;
      }
    });
  });
}
