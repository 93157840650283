/* eslint no-console:0 */
import { initAll } from 'govuk-frontend';
import { GOVUKInitAll, HMRCInitAll, ClearCookies } from 'dvla-external-frontend';

require.context('./stylesheets/', true, /^\.\/[^_].*\.(css|scss|sass)$/i);
// ensure images are included in the compiled pack
require.context('./images', true);
// ensure dvla-external-frontend/dist/media are included in the compiled pack for favicon, fonts etc
require.context('dvla-external-frontend/dist/media', true);

const ReactRailsUJS = require('react_ujs');
// create a context from our app/javascript/components directory
const customContext = require.context('./components', true);
ReactRailsUJS.useContext(customContext);
initAll();
GOVUKInitAll();
HMRCInitAll();
ClearCookies.init();
import ProgressiveDisclosure from './components/ProgressiveDisclosure';
ProgressiveDisclosure();
